import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Grid } from '@devstart/react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
  userSelector,
  codeRegistrationSelector,
  isSignedInSelector,
  selectSubscriptionLoading
} from '../redux/selectors';
import { hardGoTo as navigate, submitSubscription } from '../redux/actions';
import { User } from '../redux/prop-types';
import Input from '../components/ui/Input';
import Button from '../components/ui/Button';
import { ButtonTypes } from '../components/ui/Button/button-types';
import { Spacer } from '../components/helpers';
import {
  formatCpfValue,
  formatPhoneValue,
  isCPFValid
} from '../utils/data-complement/validate-inputs';

import './data-complement.css';
import createRedirect from '../components/create-redirect';

const mapStateToProps = createSelector(
  userSelector,
  codeRegistrationSelector,
  isSignedInSelector,
  selectSubscriptionLoading,
  (user: User, code: string, isSignedIn, subscriptionLoading: boolean) => ({
    user,
    code,
    isSignedIn,
    subscriptionLoading
  })
);

const mapDispatchToProps = {
  submitSubscription,
  navigate
};

type FormValues = {
  name: string;
  cpf: string;
  phone: string;
  subscription: boolean;
  access: string;
  codeRegistration: string;
};

type FormValidation = {
  name: string;
  cpf: string;
  phone: string;
};

type DataComplementProps = {
  name: string;
  cpf: string;
  phone: string;
  submitSubscription: (formValues: FormValues) => void;
  navigate: (location: string) => void;
  isSignedIn: boolean;
  user: User;
  code: string;
  subscriptionLoading: boolean;
};

const defaultValues = {
  codeRegistration: '',
  cpf: '',
  name: '',
  phone: '',
  subscription: false,
  access: 'limited',
  source: ''
};

const defaultValuesValidation = {
  cpf: '',
  name: '',
  phone: ''
};

const RedirectLearn = createRedirect('/learn');
const RedirectIndex = createRedirect('/');

function DataComplement({
  isSignedIn,
  user,
  submitSubscription,
  subscriptionLoading
}: DataComplementProps) {
  const [formValues, setFormValues] = useState<FormValues>(defaultValues);
  const [validationForm, setValidationForm] = useState<FormValidation>(
    defaultValuesValidation
  );

  const { t } = useTranslation();

  function handleNameChange(e: React.FormEvent<HTMLInputElement>) {
    const value = (e.target as HTMLInputElement).value.slice(0);
    setValidationForm({ ...validationForm, name: '' });
    return setFormValues({ ...formValues, name: value });
  }

  function handlePhoneChange(e: React.FormEvent<HTMLInputElement>) {
    const value = (e.target as HTMLInputElement).value.slice(0);
    setValidationForm({ ...validationForm, phone: '' });
    return setFormValues({ ...formValues, phone: value });
  }

  function handleCpfChange(e: React.FormEvent<HTMLInputElement>) {
    const value = (e.target as HTMLInputElement).value.slice(0);
    setValidationForm({ ...validationForm, cpf: '' });
    return setFormValues({ ...formValues, cpf: value });
  }

  function isEmptyFields(
    values: FormValidation
  ): Partial<Record<keyof FormValues, string>> | undefined {
    const fieldTranslations: Record<keyof FormValidation, string> = {
      name: 'Nome completo',
      phone: 'Telefone',
      cpf: 'CPF'
    };
    const fieldsToCheck: (keyof FormValidation)[] = ['name', 'phone', 'cpf'];
    const validationMessages: Partial<Record<keyof FormValidation, string>> =
      {};

    fieldsToCheck.forEach(field => {
      if (!values[field]) {
        validationMessages[
          field
        ] = `Campo '${fieldTranslations[field]}' está em branco, verfique suas informações e tente novamente.`;
      }
    });

    if (Object.keys(validationMessages).length > 0) {
      return validationMessages;
    }
  }

  function validateFields(
    values: FormValues
  ): Partial<Record<keyof FormValues, string>> | undefined {
    const validationMessages: Partial<Record<keyof FormValues, string>> = {};

    if (!formatPhoneValue(values.phone)) {
      validationMessages.phone =
        'Verifique o número de telefone e tente novamente';
    }
    if (!formatCpfValue(values.cpf)) {
      validationMessages.cpf = 'Verifique o número do CPF e tente novamente.';
    }
    if (!isCPFValid(values.cpf)) {
      validationMessages.cpf = 'Verifique o número do CPF e tente novamente.';
    }

    if (Object.keys(validationMessages).length > 0) {
      return validationMessages;
    }
  }

  function validateForm(values: FormValues) {
    let validationMessages: Partial<Record<keyof FormValues, string>> = {};

    const emptyFieldMessages = isEmptyFields(values);
    if (emptyFieldMessages) {
      validationMessages = { ...validationMessages, ...emptyFieldMessages };
    }

    const invalidFieldMessages = validateFields(values);
    if (invalidFieldMessages) {
      validationMessages = { ...validationMessages, ...invalidFieldMessages };
    }

    if (Object.keys(validationMessages).length > 0) {
      setValidationForm({ ...validationForm, ...validationMessages });
      return false;
    } else {
      return true;
    }
  }

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    if (validateForm(formValues)) {
      submitSubscription(formValues);
    }
  }

  if (!isSignedIn) {
    return <RedirectIndex />;
  }

  if (isSignedIn && user.subscription) {
    return <RedirectLearn />;
  }

  return (
    <>
      <Helmet title={`${t('register.title')} | DEVstart`} />
      <Grid>
        <Spacer size='large' />
        <div className='complement-container'>
          <div className='form-complement-container'>
            <p id='data-complement-title'>{t('data-complement.title')}</p>
            <Spacer size='small' />
            <p id='data-complement-subtitle'>{t('data-complement.subtitle')}</p>
            <Spacer size='medium' />
            <form onSubmit={handleSubmit}>
              <Input
                name='name'
                type='text'
                placeholder='Nome completo'
                value={formValues.name}
                onChange={handleNameChange}
                hasError={!!validationForm.name}
                messageError={validationForm.name}
              />
              <Input
                name='phone'
                hasMask={true}
                maskValue='(99) 99999-9999'
                placeholder='Telefone'
                value={formValues.phone}
                onChange={handlePhoneChange}
                hasError={!!validationForm.phone}
                messageError={validationForm.phone}
              />
              <Input
                name='cpf'
                hasMask={true}
                maskValue='999.999.999-99'
                placeholder='CPF'
                value={formValues.cpf}
                onChange={handleCpfChange}
                hasError={!!validationForm.cpf}
                messageError={validationForm.cpf}
              />
              <Spacer size='medium' />
              <Button
                type='submit'
                buttonType={ButtonTypes.Primary}
                style={{ height: '48px', width: '100%' }}
                isLoading={subscriptionLoading}
              >
                {t('data-complement.finish-complement')}
              </Button>
              <Spacer size='medium' />
              <p id='info-terms'>
                {t('register.headings.sign-up-accept-terms')}
              </p>
            </form>
          </div>
        </div>
      </Grid>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DataComplement);
